@font-face {
  font-family: "Journal";
  src: url("fonts/journal.eot");
  src:
    url("fonts/journal.eot?#iefix") format("embedded-opentype"),
    url("fonts/journal.woff") format("woff"),
    url("fonts/journal.ttf") format("truetype"),
    url("fonts/journal.svg#JournalRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}

.sigPad {
  margin: 0;
  padding: 0;
  width: 200px;
}

.sigPad label {
  display: block;
  margin: 0 0 0.515em;
  padding: 0;
  color: #000;
  font:
    italic normal 1em/1.375 Georgia,
    Times,
    serif;
}

.sigPad label.error {
  color: #f33;
}

.sigPad input {
  margin: 0;
  padding: 0.2em 0;
  width: 198px;
  border: 1px solid #666;
  font-size: 1em;
}

.sigPad input.error {
  border-color: #f33;
}

.sigPad button {
  margin: 1em 0 0 0;
  padding: 0.6em 0.6em 0.7em;
  background-color: #ccc;
  border: 0;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  cursor: pointer;
  color: #555;
  font: bold 1em/1.375 sans-serif;
  text-align: left;
}

.sigPad button:hover {
  background-color: #333;
  color: #fff;
}

.sig {
  display: none;
}

.sigNav {
  display: none;
  height: 2.25em;
  margin: 0;
  padding: 0;
  position: relative;
  list-style-type: none;
}

.sigNav li {
  display: inline;
  float: left;
  margin: 0;
  padding: 0;
}

.sigNav a,
.sigNav a:link,
.sigNav a:visited {
  display: block;
  margin: 0;
  padding: 0 0.6em;
  border: 0;
  color: #333;
  font-weight: bold;
  line-height: 2.25em;
  text-decoration: underline;
}

.sigNav a.current,
.sigNav a.current:link,
.sigNav a.current:visited {
  background-color: #666;
  -moz-border-radius-topleft: 8px;
  -moz-border-radius-topright: 8px;
  -webkit-border-top-left-radius: 8px;
  -webkit-border-top-right-radius: 8px;
  border-radius: 8px 8px 0 0;
  color: #fff;
  text-decoration: none;
}

.sigNav .typeIt a.current,
.sigNav .typeIt a.current:link,
.sigNav .typeIt a.current:visited {
  background-color: #ccc;
  color: #555;
}

.sigNav .clearButton {
  bottom: 0.2em;
  display: none;
  position: absolute;
  right: 0;
  font-size: 0.75em;
  line-height: 1.375;
}

.sigWrapper {
  clear: both;
  height: 55px;
  border: 1px solid #ccc;
}

.sigWrapper.current {
  border-color: #666;
}

.signed .sigWrapper {
  border: 0;
}

.pad {
  user-select: none;
  position: relative;
  /* cursor: url("../assets/pen.cur"), crosshair; */
}

.typeItDesc,
.drawItDesc {
  display: none;
  margin: 0.75em 0 0.515em;
  padding: 0.515em 0 0;
  border-top: 3px solid #ccc;
  color: #000;
  font:
    italic normal 1em/1.375 Georgia,
    Times,
    serif;
}

p.error {
  display: block;
  margin: 0.5em 0;
  padding: 0.4em;
  background-color: #f33;
  color: #fff;
  font-weight: bold;
}
